<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col md="6" lg="6" xl="6">
        <v-card class="pa-1" outlined shaped>
          <v-card-title>Sınav Bilgileri</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="name"
                label="Sınav İsmi"
                :rules="nameRules"
                outlined
              ></v-text-field>
              <v-menu
                v-model="startmenu"
                :close-on-content-click="false"
                :nudge-right="30"
                :nudge-top="20"
                offset-y
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    label="Başlangıç Tarihi"
                    append-icon="mdi-calendar"
                    :rules="dateRules"
                    readonly
                    v-bind="attrs"
                    outlined
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <date-picker
                  v-model="startdate"
                  type="datetime"
                  value-type="format"
                  :disabled-date="disabledDates"
                  :disabled-time="disabledTimes"
                  inline
                ></date-picker>
              </v-menu>
              <v-menu
                v-model="finishmenu"
                :close-on-content-click="false"
                :nudge-right="30"
                :nudge-top="20"
                offset-y
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="finishdate"
                    label="Bitiş Tarihi"
                    append-icon="mdi-calendar"
                    :rules="dateRules"
                    readonly
                    v-bind="attrs"
                    outlined
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <date-picker
                  v-model="finishdate"
                  type="datetime"
                  value-type="format"
                  :disabled-date="disabledDates"
                  :disabled-time="disabledTimes"
                  inline
                ></date-picker>
              </v-menu>
              <v-select
                v-model="selectedTests"
                :items="tests"
                :menu-props="{ maxHeight: '400' }"
                :rules="testRules"
                item-value="_id"
                item-text="name"
                label="Testler"
                multiple
                hint="Sınav içinde olacak testler"
                outlined
                persistent-hint
              ></v-select>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="6" lg="6" xl="6">
        <v-card class="pa-1" outlined shaped>
          <v-card-title>Sınav Ayarları</v-card-title>
          <v-card-text>
            <v-form ref="form2" class="pa-0 ma-0">
              <v-checkbox
                v-model="isOneUse"
                class="pa-0 ma-0"
                label="Tek Kullanım Mı ?"
              />
              <v-checkbox
                v-model="isTCUse"
                class="pa-0 ma-0"
                label="T.C. Sorulsun Mu ?"
              />
              <v-checkbox
                v-model="isSendMail"
                class="pa-0 ma-0"
                label="Mail Gönderilsin Mi ?"
              />
              <v-checkbox
                v-model="isApplicationSourceUse"
                class="pa-0 ma-0"
                label="Başvuru Kaynağı Sorulsun Mu ?"
              />
              <v-select
                v-if="isApplicationSourceUse"
                v-model="valueApplicationSource"
                :items="itemsApplicationSource"
                class="pa-0 ma-0"
                dense
                attach
                chips
                label="Başvuru Kaynakları"
                multiple
                :menu-props="{ maxHeight: 150 }"
              ></v-select>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5 pa-2">
      <v-btn
        block
        elevation="3"
        :disabled="!valid"
        color="primary"
        large
        @click="create"
      >
        Oluştur
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@axios';
import dateformat from 'dateformat';

const now = new Date();

export default {
  name: 'ExamCreate',

  components: {},
  data: () => ({
    name: '',
    password: '',
    isOneUse: false,
    isTCUse: false,
    isSendMail: false,
    isApplicationSourceUse: false,
    valid: false,
    showPassword: false,
    startmenu: false,
    finishmenu: false,
    finishdate: '',
    startdate: dateformat(now, 'yyyy-mm-dd HH:MM:ss'),
    tests: [],
    itemsApplicationSource: [
      'Kariyer.net',
      'Linkedin.com',
      'SecretCV.com',
      'Yenibiris.com',
      'Youthall.com',
      'Firma Kariyer Sayfası',
      'Diğer'
    ],
    selectedTests: [],
    valueApplicationSource: ['Diğer'],
    nameRules: [(v) => !!v || 'İsim girmek zorunlu'],
    dateRules: [(v) => !!v || 'Tarih girmek zorunlu'],
    testRules: [(v) => v.length !== 0 || 'En az bir test seçilmeli']
  }),
  mounted() {
    axios
      .get('/list-test')
      .then((response) => {
        if (response?.data?.result) {
          this.tests = response.data.result;
        }
      })
      .catch(() => {
        this.$toast.error('Error while getting test list');
      });
  },
  methods: {
    disabledDates(val) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      return val < now;
    },
    disabledTimes(val) {
      const now = new Date();
      let today =
        val.getDate() === now.getDate() &&
        val.getMonth() === now.getMonth() &&
        val.getFullYear() === now.getFullYear();
      const hours = now.getHours();
      return today ? val.getHours() <= hours : false;
    },
    create() {
      const startdate = new Date(this.startdate).toISOString();
      const finishdate = new Date(this.finishdate).toISOString();

      axios
        .post('/create-exam', {
          name: this.name,
          startdate,
          finishdate,
          tests: this.selectedTests,
          isUseOne: !this.isOneUse ? false : this.isOneUse,
          isTCUse: !this.isTCUse ? false : this.isTCUse,
          isSendMail: !this.isSendMail ? false : this.isSendMail,
          takePhoto: false,
          isApplicationSourceUse: !this.isApplicationSourceUse
            ? false
            : this.isApplicationSourceUse,
          valueApplicationSource: this.valueApplicationSource
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success('Sınav Oluşturuldu.');
            this.$refs.form.reset();
            this.$refs.form2.reset();
            this.$emit('onExamCreate');
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    }
  }
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin: 0;
  }
}
</style>
