<template>
  <div>
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="items"
      :items-per-page="10"
      :loading="isLoading"
      item-key="_id"
      multi-sort
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogEdit" max-width="750px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Sınav Düzenleme</span>
              </v-card-title>
              <v-card-text>
                <v-container class="grey lighten-5">
                  <v-row>
                    <v-col md="6" lg="6" xl="6">
                      <v-card class="pa-1" outlined shaped>
                        <v-card-title>Sınav Bilgileri</v-card-title>
                        <v-card-text>
                          <v-text-field
                            v-model="editedItem.name"
                            label="Sınav İsmi"
                            :rules="editedItem.nameRules"
                            outlined
                          ></v-text-field>
                          <v-menu
                            v-model="editedItem.startmenu"
                            :close-on-content-click="false"
                            :nudge-right="30"
                            :nudge-top="20"
                            offset-y
                            transition="scale-transition"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.startdate"
                                label="Başlangıç Tarihi"
                                append-icon="mdi-calendar"
                                :rules="editedItem.dateRules"
                                readonly
                                v-bind="attrs"
                                outlined
                                v-on="on"
                              >
                              </v-text-field>
                            </template>
                            <date-picker
                              v-model="editedItem.startdate"
                              type="datetime"
                              value-type="format"
                              inline
                            ></date-picker>
                          </v-menu>
                          <v-menu
                            v-model="editedItem.finishmenu"
                            :close-on-content-click="false"
                            :nudge-right="30"
                            :nudge-top="20"
                            offset-y
                            transition="scale-transition"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.finishdate"
                                label="Bitiş Tarihi"
                                append-icon="mdi-calendar"
                                :rules="editedItem.dateRules"
                                readonly
                                v-bind="attrs"
                                outlined
                                v-on="on"
                              >
                              </v-text-field>
                            </template>
                            <date-picker
                              v-model="editedItem.finishdate"
                              type="datetime"
                              value-type="format"
                              :disabled-date="disabledDates"
                              :disabled-time="disabledTimes"
                              inline
                            ></date-picker>
                          </v-menu>
                          <v-select
                            v-model="editedItem.selectedTests"
                            :items="editedItem.tests"
                            :menu-props="{ maxHeight: '400' }"
                            :rules="editedItem.testRules"
                            item-value="_id"
                            item-text="name"
                            label="Testler"
                            multiple
                            hint="Sınav içinde olacak testler"
                            outlined
                            persistent-hint
                          ></v-select>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="6" lg="6" xl="6">
                      <v-card class="pa-1" outlined shaped>
                        <v-card-title>Sınav Ayarları</v-card-title>
                        <v-card-text>
                          <v-form ref="form2" class="pa-0 ma-0">
                            <v-checkbox
                              v-model="editedItem.isOneUse"
                              class="pa-0 ma-0"
                              label="Tek Kullanım Mı ?"
                            />
                            <v-checkbox
                              v-model="editedItem.isTCUse"
                              class="pa-0 ma-0"
                              label="T.C. Sorulsun Mu ?"
                            />
                            <v-checkbox
                              v-model="editedItem.isApplicationSourceUse"
                              class="pa-0 ma-0"
                              label="Başvuru Kaynağı Sorulsun Mı ?"
                            />
                            <v-select
                              v-if="editedItem.isApplicationSourceUse"
                              v-model="editedItem.valueApplicationSource"
                              :items="editedItem.itemsApplicationSource"
                              class="pa-0 ma-0"
                              dense
                              attach
                              chips
                              label="Başvuru Kaynakları"
                              multiple
                              :menu-props="{ maxHeight: 150 }"
                            ></v-select>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDialogEdit">
                  İptal Et
                </v-btn>
                <v-btn color="green darken-1" text @click="saveDialogEdit">
                  Kaydet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Silmek İstediğinizden Emin Misiniz ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDelete"
                  >İptal Et</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >Evet</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        <span>{{ new Date(item.startDate).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.finishDate`]="{ item }">
        <span>{{ new Date(item.finishDate).toLocaleString() }}</span>
      </template>
      <template v-slot:[`item.isUseOne`]="{ item }">
        <v-icon v-if="item.isUseOne" large color="green darken-2"
          >mdi-check-bold
        </v-icon>
        <v-icon v-else large color="red darken-2">mdi-close-thick</v-icon>
      </template>
      <template v-slot:[`item.tests`]="{ item }">
        <span v-for="(next, i) in item.tests" :key="i">
          {{ next.name }}
          <span v-if="i != Object.keys(item.tests).length - 1">, </span>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.isActive"
          small
          class="mr-2"
          @click="passivateItem(item)"
        >
          mdi-check-bold
        </v-icon>
        <v-icon v-else small class="mr-2" @click="activateItem(item)">
          mdi-close-thick
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from '@axios';

export default {
  name: 'ExamList',

  components: {},
  props: {
    update: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data: () => ({
    headerProps: {
      sortByText: 'Sırala'
    },
    isLoading: true,
    dialogDelete: false,
    dialogEdit: false,
    selectedDeleteItemId: '',
    headers: [
      {
        text: 'Sınav İsmi',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Sınav Kodu',
        value: 'code'
      },
      {
        text: 'Sınav Linki',
        value: 'url',
        width: '15%'
      },
      {
        text: 'Testler',
        value: 'tests'
      },
      {
        text: 'Tek Kullanımlık',
        value: 'isUseOne'
      },
      {
        text: 'Başlangıç Tarihi',
        value: 'startDate'
      },
      {
        text: 'Bitiş Tarihi',
        value: 'finishDate'
      },
      {
        text: 'İşlemler',
        value: 'actions',
        width: '100px',
        sortable: false
      }
    ],
    items: [],
    editedItem: {
      _id: '',
      name: '',
      startdate: '',
      finishdate: '',
      tests: [],
      selectedTests: [],
      nameRules: [(v) => !!v || 'İsim girmek zorunlu'],
      dateRules: [(v) => !!v || 'Tarih girmek zorunlu'],
      testRules: [(v) => v.length !== 0 || 'En az bir test seçilmeli'],
      startmenu: false,
      finishmenu: false,
      isOneUse: false,
      isTCUse: false,
      isApplicationSourceUse: false,
      itemsApplicationSource: [
        'Kariyer.net',
        'Linkedin.com',
        'SecretCV.com',
        'Yenibiris.com',
        'Youthall.com',
        'Firma Kariyer Sayfası',
        'Diğer'
      ],
      valueApplicationSource: ['Diğer']
    }
  }),
  computed: {
    valid() {
      return this.name.length && this.code.length;
    }
  },
  watch: {
    update: function () {
      this.list();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeDialogEdit();
    }
  },
  mounted() {
    this.list();
    this.listTest();
  },
  methods: {
    disabledDates(val) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      return val < now;
    },
    disabledTimes(val) {
      const now = new Date();
      let today =
        val.getDate() === now.getDate() &&
        val.getMonth() === now.getMonth() &&
        val.getFullYear() === now.getFullYear();
      const hours = now.getHours();
      return today ? val.getHours() <= hours : false;
    },
    activateItem(item) {
      axios
        .post(`/activate-exam/${item._id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Sınav Aktif Edildi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    passivateItem(item) {
      axios
        .post(`/passivate-exam/${item._id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Sınav Pasif Edildi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedDeleteItemId = item._id;
    },
    editItem(item) {
      this.editedItem = {
        _id: item._id,
        name: item.name,
        startdate: new Date(item.startDate).toLocaleString(),
        finishdate: new Date(item.finishDate).toLocaleString(),
        isOneUse: item.isUseOne,
        isTCUse: item.isTCUse,
        isApplicationSourceUse: item.isApplicationSourceUse,
        itemsApplicationSource: this.editedItem.itemsApplicationSource,
        valueApplicationSource: item.valueApplicationSource,
        tests: this.editedItem.tests,
        selectedTests: item.tests
      };
      this.dialogEdit = true;
    },
    deleteItemConfirm() {
      this.closeDelete();
      axios
        .post(`/delete-exam/${this.selectedDeleteItemId}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Sınav Silindi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeDialogEdit() {
      this.dialogEdit = false;
    },
    saveDialogEdit() {
      const startdate = new Date(this.editedItem.startdate).toISOString();
      const finishdate = new Date(this.editedItem.finishdate).toISOString();

      axios
        .post(`/edit-exam/${this.editedItem._id}`, {
          name: this.editedItem.name,
          startdate,
          finishdate,
          tests: this.editedItem.selectedTests,
          isUseOne: this.editedItem.isOneUse,
          isTCUse: this.editedItem.isTCUse,
          isSendMail: false,
          takePhoto: false,
          isApplicationSourceUse: this.editedItem.isApplicationSourceUse,
          valueApplicationSource: this.editedItem.isApplicationSourceUse
            ? this.editedItem.valueApplicationSource
            : []
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Sınav Güncellendi.');
            this.list();
            this.dialogEdit = false;
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    list() {
      axios.get('/list-exam').then((response) => {
        this.isLoading = false;
        const items = response.data.result;
        this.items = items;
      });
    },
    listTest() {
      axios
        .get('/list-test')
        .then((response) => {
          if (response?.data?.result) {
            this.editedItem.tests = response.data.result;
          }
        })
        .catch(() => {
          this.$toast.error('Error while getting test list');
        });
    }
  }
};
</script>

<style lang="scss"></style>
